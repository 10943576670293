<template>
    <v-app>
    <v-container fluid class="blue lighten-4 login">
        <v-row 
            justify="center"
            align="center"
        >
        
            <v-col xs="10" sm="6" md="6" lg="4">
                <v-card>
                    <v-card-title class="text-center pa-6">
                        <h4 class="login_title">新日本住設株式会社<br>スマートハウス 販売支援サイト 管理画面</h4>
                    </v-card-title>
                    <v-divider> </v-divider>
                    <div class="pa-6">

                        <v-alert
                            v-if="errorMessageShow"
                            border="top"
                            type="error"
                            dark
                        >{{errorMessage}}</v-alert>

                        <v-text-field
                            v-model="email"
                            :rules="[emailRules.required]"
                            outlined
                            label="メールアドレス"
                            class="mb-3"
                        ></v-text-field>

                        <v-text-field
                            v-model="password"
                            :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[passwordRules.required]"
                            :type="passwordShow ? 'text' : 'password'"
                            outlined
                            label="パスワード"
                            @click:append="passwordShow = !passwordShow"
                            class="mb-4"
                        ></v-text-field>
                        <div class="login-btn text-center">
                            <v-btn
                                @click="login"
                                class="font-weight-bold"
                                color="blue-grey darken-4"
                                dark
                                large
                            >
                                ログイン
                            </v-btn>
                        </div>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
    </v-app>
</template>

<script>
    export default {
        data() {
            return {
                email: null,
                emailRules: {
                    required: value => !!value || 'メールアドレスを入力してください',
                },
                password: null,
                passwordShow: false,
                passwordRules: {
                    required: value => !!value || 'パスワードを入力してください',
                },
                errorMessage: '',
                errorMessageShow : false,
            };
        },
        methods: {
            login: function(){
                this.$axios.get("/api/csrf-cookie").then(response => {
                    response;
                    this.$axios.post("/api/admin/login", {
                        email: this.email,
                        password: this.password
                    })
                    .then(response => {
                        if(response.data.result){
                            //ログイン成功
                            this.errorMessage = '';
                            this.errorMessageShow = false;
                            this.$router.push('/admin/');
                        }else{
                            this.errorMessage = 'メールアドレスもしくはパスワードが違います';
                            this.errorMessageShow = true;
                        }
                    })
                    .catch(error => {
                        if(error.response.status === 429){
                                this.errorMessage = 'ログイン試行回数の制限に達しました。しばらく経ってからもう一度やり直してください。';
                                this.errorMessageShow = true;
                        }
                        else{
                            this.errorMessage = 'サーバーエラーが発生しました';
                            this.errorMessageShow = true;
                        }
                    });
                });
            }
        },
    };
</script>

<style lang="scss">
    .login_title {
        width: 100%;
    }
    .login {
        height: 100%;
        .row {
            height: 100%;
        }
    }
</style>