<template>
    <v-container grid-list-md>
        <h2 class="pa-2">見積もり依頼一覧</h2>
        <v-divider></v-divider>

        <v-row justify="center" align="center">
            <v-col lg="10">
                <v-card outlined>
                    <v-card-title class="py-2 px-4 indigo">
                        <h3 class="subtitle-1 font-weight-bold white--text">
                            検索
                        </h3>
                    </v-card-title>
                    <v-divider> </v-divider>
                    <v-row class="pa-6 pb-0">
                        <v-col cols="12" md="6">
                            <v-select
                                :items="[{builder_name:'全て' , id : ''} , ...builders]"
                                item-text="builder_name"
                                item-value="id"
                                outlined
                                hide-details
                                label="ビルダー名"
                                flat
                                color="indigo darken-2"
                                v-model="searchForm.builder_id"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                label="名前"
                                v-model="searchForm.name"
                                outlined
                                hide-details
                                color="cyan darken-3"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                label="ステータス"
                                v-model="searchForm.status"
                                :items="[{label:'全て' , value : ''} , ...statuses]"
                                item-text="label"
                                item-value="value"
                                outlined
                                hide-details
                                flat
                                color="cyan darken-3"
                            ></v-select>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-select
                                v-model="searchForm.situation"
                                :items="situation"
                                item-text="label"
                                item-value="value"
                                outlined
                                hide-details
                                label="状況"
                                flat
                                color="cyan darken-3"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row class="pa-6 pt-0 pb-2">
                        <v-col cols="12" md="6">
                            <v-checkbox
                                label="見積依頼予定も表示する"
                                color="indigo darken-2"
                                value="1"
                                hide-details
                                v-model="searchForm.will"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <div class="text-center pb-4">
                        <v-btn class="ma-2" outlined color="cyan darken-3" @click="quoteSearch()">
                            <v-icon>mdi-magnify</v-icon>
                            検索
                        </v-btn>
                    </div>
                </v-card>
            </v-col>
        </v-row>

        <v-row justify="center" align="center">
            <v-col lg="10">
                <v-card>
                    <QuoteTable
                        @changePage="changePage"
                        @getQuote="getQuote"
                        :items="items"
                        :page="page"
                        :pageCount="pageCount"
                        :itemsPerPage="itemsPerPage"
                        :statuses="statuses"
                        :builders="buildersFlat"
                    />
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import QuoteTable from "./QuoteTable";
import Search from "../../../mixins/Search.js";
export default {
    components: { QuoteTable },
    mixins: [Search],
    data() {
        return {
            name: null,
            buttonStatus: false,
            registDialog: false,
            builders: [],
            buildersFlat:{},
            errors: [],
            //検索条件
            searchForm: {
                //検索のデフォルト値
                name: '',
                status: '',
                situation: '',
                builder_id: '',
                will:''
            },
            //search用
            searchScheme: ["name", "status", "situation" , "builder_id" , "will"],
            searchURL: "/api/admin/quotes",
            //マスターデータ
            statuses: [],
            situation: [
                {
                    label: "受注・失注を除く",
                    value: "",
                },
                {
                    label: "全て",
                    value: "all",
                },
            ],
        };
    },
    methods: {
        addQuote() {
            this.buttonStatus = true;
            let post = {
                name: this.name,
            };
            //エラーが存在しいない場合、登録処理を実行
            this.$axios
                .post("/api/builder/addQuote", post)
                .then((response) => {
                    if (response.data.result == true) {
                        this.$store.commit("setMessege", {
                            text: "見積依頼を追加しました。",
                            color: "success",
                        });
                        this.registDialog = false;
                        this.buttonStatus = false;
                        this.getQuote(true);
                    }
                })
                .catch((error) => {
                    let errors = error.response.data.errors;
                    this.errors = errors;
                    this.buttonStatus = false;
                });
        },

        getQuote(scrollFlg) {
            let query = this.$route.query;
            query.scrollFlg = scrollFlg;
            this.search(query);
        },
        //検索ボタンがクリックされた時
        quoteSearch() {
            let value = {};
            for (const key of this.searchScheme) {
                value[key] = this.searchForm[key];
            }
            this.searchForm = value;
            let query = this.getSearchBase();
            this.search(query);
        },
    },
    mounted() {
        (async () => {
            //先にマスターデータを取得しておく。
            await this.$axios.get("/api/masters/statuses").then((response) => {
                this.statuses = response.data;
            });
            await this.$axios
                .get("/api/admin/builders", {
                    params: { all: "1" },
                })
                .then((response) => {
                    this.builders = response.data;
                    for (const item of response.data) {
                        this.buildersFlat[item.id] = item.builder_name;
                    }
                });
            this.getQuote();
        })();
    },
};
</script>
