import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import axios from "axios";
import store from "./store";

axios.defaults.withCredentials = true;
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

axios.interceptors.response.use(
    function(response) {
        return response;
    },
    function(error) {
        // 認証エラー時の処理
        if (error.response.status === 401 || error.response.status === 419) {
            store.commit("setMessege", {
                text:
                    "ログイン状態ではありません。リロードし再度ログインしてください。",
                color: "warning",
            });
        } else if (error.response.status === 500) {
            store.commit("setMessege", {
                text:
                    "システムエラーが発生しました。リロード後にもう一度試していただき、改善しなければ管理者へご連絡ください。",
                color: "error",
            });
        } else if (error.response.status === 429) {
            store.commit("setMessege", {
                text:
                    "通信回数制限に達しました。1分後にもう一度試してください。",
                color: "warning",
            });
        }

        return Promise.reject(error);
    }
);

new Vue({
    vuetify,
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
