<template>
    <v-app>
        <v-snackbar
            v-model="message.view"
            class="headerSnackbar"
            :color="message.color"
            :timeout="message.timeout"
            centered
            top
        >
            {{ message.text }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="message.view = false"
                >
                    閉じる
                </v-btn>
            </template>
        </v-snackbar>

        <Header />
        <v-main class="cyan lighten-5">
            <router-view/>
        </v-main>
    </v-app>
</template>

<script>
import Header from "./Header";
export default {
  components: { Header },
    data() {
        return {
            drawer: false,
            headerMessageView:true,
            headerMessage:'hoghoge'
        }
    },
    computed: {
        message(){
            return this.$store.state.message;
        },
    }
};
</script>
<style>
    .headerSnackbar{
        z-index:1000;
    }
</style>