<template>
    <v-navigation-drawer 
    app
    clipped
    mobile-breakpoint="960"
    v-model="setDrawer"
    color="gray lighten-5"
    >
        <v-container>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title
                        class="title cyan--text text--darken-4"
                    >
                       MENU
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list nav dense>
                <template v-for="nav_list in nav_lists">
                    <v-list-item
                        v-if="!nav_list.lists"
                        :to="nav_list.link"
                        :href="nav_list.href"
                        :target="nav_list.target"
                        :key="nav_list.name"
                        @click="menu_close"
                    >
                        <v-list-item-icon>
                            <v-icon>{{ nav_list.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ nav_list.name }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </v-container>
    </v-navigation-drawer>
</template>

<script>
export default {
    methods: {
        menu_close() {
            this.nav_lists.forEach((nav_list) => (nav_list.active = false));
        },
    },
    props: {
        drawer: {
            type: Boolean,
            default: null
        }
    },
    computed: {
        setDrawer: {
            get () { return this.drawer },
            set (val) { return this.$emit('update:drawer', val) }
        }
    },
    data() {
        return {
            nav_lists: [
                {
                    name: "ご説明動画",
                    icon: "mdi-youtube-tv",
                    link: "/",
                },
                {
                    name: "FAQ",
                    icon: "mdi-comment-question",
                    href: "https://snj-faq.info/",
                    target: "blank"
                },
                {
                    name: "見積依頼一覧",
                    icon: "mdi-view-list-outline",
                    link: "/quote_list",
                },
            ],
        }
    },
};
</script>
