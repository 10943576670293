<template>
    <div>
        <Navigation />
        <v-app-bar color="indigo darken-2" clipped-left dark app>
            <v-toolbar-title
                >管理者 スマートハウス 販売支援サイト</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" color="indigo" depressed dark>
                            <v-icon dark>
                                mdi-wrench
                            </v-icon>
                            <v-icon>mdi-menu-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-for="adminUserMenu in adminUserMenus"
                            :key="adminUserMenu.name"
                            :to="adminUserMenu.link"
                            @click="adminUserMenu.action"
                        >
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ adminUserMenu.name }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-toolbar-items>
        </v-app-bar>
    </div>
</template>

<script>
import Navigation from "./Navigation";

export default {
    components: { Navigation },
    data() {
        return {
            drawer: null,
            adminUserMenus: [
                {
                    name: "パスワード変更",
                    link: "/admin/password",
                    action: () => {},
                },
                {
                    name: "ログアウト",
                    action: this.logout,
                },
            ],
        };
    },
    methods: {
        logout: function() {
            this.$axios.get("/api/admin/logout", {}).then((response) => {
                if (response) {
                    this.$router.push("/admin/login");
                }
            });
        },
    },
};
</script>
