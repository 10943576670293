import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state : {
        //ヘッダ フラッシュメッセージ
        message: {
            'text' : '',
            'color' : 'success',
            'view' : false,
            'timeout' : 5000,
        },
    },
    mutations : {
        setMessege(state, { text, color }) {
            state.message.text = text;
            state.message.color = color;
            if(color == 'error'){
                state.message.timeout = -1;
            }
            state.message.view = true;
        },
        resetMessage (state) {
            state.message.text = '';
            state.message.view = false;
          }
    }

  })