<template>
    <div>
        <v-data-table
        :headers="headers"
        :items="items"
        hide-default-footer
        :hide-default-header="isMobile"
        :class="{mobile: isMobile}"
        v-resize="onResize"
        no-data-text = 'データがありません'
        >
            <template v-slot:item="{ item }">

                <!-- PC -->
                <tr v-if="!isMobile">
                    <td>
                    {{ item.created_at | moment(dateFormat)}}
                    </td>
                    <td>
                    {{ item.name }}
                    </td>
                    <td>
                        <div class="pa-4">
                            <v-select
                            :error-messages="quoteStatusErrors[item.id]"
                            v-model="item.status" 
                            :items="statuses"
                            item-text="label"
                            item-value="value"
                            color="cyan darken-3"
                            dense
                            outlined
                            label="ステータス未選択"
                            solo
                            flat
                            @change="changedStatus(item.id, item.status)"
                            ></v-select>
                        </div>
                    </td>
                </tr>

                <!-- smartphone -->
                <tr v-else>
                    <td>
                        <ul class="flex-content">
                            <li class="flex-item" data-label="追加日">{{ item.created_at | moment(dateFormat) }}</li>
                            <li class="flex-item" data-label="氏名">{{ item.name }}</li>
                            <li class="flex-item pb-3">
                                <v-select
                                :error-messages="quoteStatusErrors[item.id]"
                                v-model="item.status" 
                                :items="statuses"
                                item-text="label"
                                item-value="value"
                                color="cyan darken-3"
                                dense
                                outlined
                                label="ステータス未選択"
                                solo
                                flat
                                @change="changedStatus(item.id, item.status)"
                                ></v-select>
                            </li>
                        </ul>
                    </td>
                </tr>

            </template>
        </v-data-table>

        <div class="text-center py-5">
            <v-pagination
                v-model="dataPage"
                :length="pageCount"
                @input="changePage()"
                color="indigo"
            ></v-pagination>
        </div>

    </div>
</template>

<script>
    import moment from "moment";
    export default {
        props: {
            statuses: Array,
            items: Array,
            page: Number,
            pageCount: Number,
            itemsPerPage: Number,
        },
        data() {
            return {
                isMobile: false,
                dateFormat : 'YYYY-MM-DD',
                dataPage: this.page,
                quoteStatusErrors : {},
                headers: [
                    {
                        text: '追加日',
                        sortable: false,
                        value: 'date',
                        width: '20%'
                    },
                    {
                        text: '氏名',
                        sortable: false,
                        value: 'name',
                        width: '40%'
                    },
                    {
                        text: 'ステータス',
                        sortable: false,
                        value: 'status',
                        width: '30%'
                    }
                ],
            }
        },
        methods: {
            onResize() {
                if (window.innerWidth < 769)
                    this.isMobile = true;
                else
                    this.isMobile = false;
            },
            //ページングがクリックされた時
            changePage() {
                let value = {
                    page: this.dataPage,
                    pageCount: this.pageCount,
                    itemsPerPage: this.itemsPerPage,
                };
                this.$emit('changePage', value);
            },
            changedStatus(id , status) {
                let post = {
                    id: id, 
                    status: status, 
                };
                post = {
                    id: id, 
                    status: status, 
                };
                this.$store.commit('resetMessage');
                this.$axios.post("/api/builder/editStatus", post)
                .then(response => {
                    if(response.data.result == true){
                        this.$store.commit('setMessege', {
                            text : 'スターテスを変更しました。',
                            color : 'success'
                        });
                    }
                    this.$emit('getQuote', false);
                })
                .catch(error => {
                    let errors = error.response.data.errors.status;
                    this.$set(this.quoteStatusErrors, id, errors);
                    error;
                });
            }
        },
        filters: {
            /**
             * @param {Date} value    - Date オブジェクト
             * @param {string} format - 変換したいフォーマット
             */
            moment(value, format) {
            return moment(value).format(format);
            }
        }
    }
</script>

<style type="scss">
@media screen and (min-width: 769px) {
    .layout {
        display: block;
        margin: 0 !important;
    }
}
@media screen and (max-width: 768px) {
      .mobile .v-data-table__wrapper table tr {
        max-width: 100%;
        position: relative;
        display: block;
      }

      .mobile .v-data-table__wrapper table tr td {
        display: flex;
        width: 100%;
        border-bottom: 1px solid #f5f5f5;
        height: auto !important;
        padding: 10px;
      }

      .mobile .v-data-table__wrapper table tr td ul {
          padding-left: 0;
      }
      .mobile .v-data-table__wrapper table tr td ul li:before {
        content: attr(data-label);
        padding-right: .5em;
        text-align: left;
        display: block;
        color: #999;
      }
      .v-select.v-input--dense .v-select__selection--comma {
            margin: 5px 4px 3px 0;
            font-size: 12px;
      }
      .v-select__slot label {
            font-size: 12px;
      }
    }
    .flex-content {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    .flex-item {
      padding: 5px;
      width: 50%;
      height: auto !important;
      font-weight: bold;
    }
</style>