<template>
    <v-container fluid class="login">
        <v-row 
            justify="center"
            align="center"
        >
            <v-col xs="10" sm="6" md="6" lg="4">
                <v-card>
                    <v-card-title class="pa-6">
                        <h4 class="login_title">パスワード変更</h4>
                    </v-card-title>
                    <v-divider> </v-divider>
                    <div class="pa-6">
                         <v-text-field
                            v-model="currentPass"
                            :append-icon="passwordShow3 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="passwordShow3 ? 'text' : 'password'"
                            outlined
                            label="現在のパスワード"
                            :error-messages = "this.errors.current_password"
                            :error-count = "this.errors.current_password ? this.errors.current_password.length : 0"
                            @click:append="passwordShow3 = !passwordShow3"
                        ></v-text-field>

                        <v-divider class="mb-3"></v-divider>

                        <v-text-field
                            v-model="newPass"
                            :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="passwordShow ? 'text' : 'password'"
                            outlined
                            label="新しいパスワード"
                            :error-messages = "this.errors.new_password"
                            :error-count = "this.errors.new_password ? this.errors.new_password.length : 0"
                            @click:append="passwordShow = !passwordShow"
                            class="mb-3"
                        ></v-text-field>

                        <v-text-field
                            v-model="newPassConfirm"
                            :append-icon="passwordShow2 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="passwordShow2 ? 'text' : 'password'"
                            outlined
                            label="新しいパスワードの確認"
                            :error-messages = "this.errors.new_password_confirmation"
                            :error-count = "this.errors.new_password_confirmation ? this.errors.new_password_confirmation.length : 0"
                            @click:append="passwordShow2 = !passwordShow2"
                            class="mb-4"
                        ></v-text-field>
                        <div class="login-btn text-center">
                            <v-btn
                            class="font-weight-bold"
                            color="amber lighten-2"
                            large
                            @click="change"
                            >
                                変更する
                            </v-btn>
                        </div>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                currentPass: null,
                newPass: null,
                passwordShow: false,
                newPassConfirm: null,
                passwordShow2: false,
                passwordShow3: false,
                errors : {},
            }
        },
        methods: {
            change: function(){
                this.$axios.post("/api/builder/changePassword", {
                    current_password: this.currentPass,
                    new_password: this.newPass,
                    new_password_confirmation: this.newPassConfirm
                })
                .then(response => {
                    if(response){
                        this.$router.push('/');
                        this.$store.commit('setMessege', {
                            text : 'パスワードを変更しました。',
                            color : 'success',
                        });
                    }
                })
                .catch(error => {
                    let errors = error.response.data.errors;
                    this.errors = errors;
                });
            }
        },
        ///
    }
</script>

<style lang="scss">
    .login_title {
        width: 100%;
    }
    .login {
        height: 100%;
        .row {
            height: 100%;
        }
    }
</style>
