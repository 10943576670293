import axios from "axios";

export function checkAuthAdmin() {
    return new Promise((resolve) => {
        axios.get("/api/admin/getLoginUser").then((response) => {
            if (response.status == 200 && response.data != 0) {
                resolve(true);
            }
            resolve(false);
        });
    })
}
export function checkAuthBuilder() {
    return new Promise((resolve) => {
        axios.get("/api/builder/getLoginUser").then((response) => {
            if (response.status == 200 && response.data != 0) {
                resolve(true);
            }
            resolve(false);
        });
    })
}
