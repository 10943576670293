<template>
    <v-row 
        justify="center"
        align="center"
    >
        <v-col lg="10">
            <v-card 
            outlined
            >
                <v-card-title
                class="py-2 px-4 white--text indigo darken-2"
                >
                    <h3 class="subtitle-1 font-weight-bold">検索</h3>
                </v-card-title>
                <v-divider> </v-divider>

                <v-row 
                class="pa-6 pb-2"
                >
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="searchForm.name"
                            outlined
                            :label="this.searchNameLabel"
                            hide-details
                            color="indigo darken-2"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="searchForm.email"
                            outlined
                            label="メールアドレス"
                            color="indigo darken-2"
                            hide-details
                        ></v-text-field>
                    </v-col>
                </v-row>

                <div class="text-center pb-4">
                    <v-btn
                    class="ma-2"
                    outlined
                    color="indigo darken-2"
                    @click="userSearch(searchForm.name,searchForm.email)"
                    >
                    <v-icon>mdi-magnify</v-icon>
                    検索
                    </v-btn>
                </div>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        data() {
            return {
                searchNameLabel: this.searchForm.label,
            }
        },
        props:{
            here: {
                type: String,
            },
            label: {
                type: String,
            },
            searchForm: Object,
        },
        methods: {
            //検索ボタンがクリックされた時
            userSearch(name, email) {
                let value = {
                    name: name,
                    email: email,
                };
                this.$emit('userSearch', value);
            },
        },
    }
</script>

<style type="scss">
.theme--light.v-sheet--outlined {
    border-color: #0277BD;
}
</style>