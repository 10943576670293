<template>
    <v-container grid-list-md>
        <h2 class="pa-2">ユーザー管理</h2>
        <v-divider></v-divider>
        <div class="text-center my-9">
            <v-dialog
            v-model="registDialog"
            max-width="600px"
            >
                <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        class="font-weight-bold"
                        x-large
                        color="deep-orange lighten-1"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        @click="registDialog = false"
                        >
                        ユーザーを追加
                        </v-btn>
                </template>
                <v-card>
                    <v-card-title class="text-center pa-6">
                        <h4 class="login_title">ユーザーを新規登録</h4>
                    </v-card-title>
                    <v-divider> </v-divider>
                    <v-card-text>
                        <div class="pa-6 pb-0">
                            <v-text-field
                                v-model="name"
                                outlined
                                label="氏名"
                                class="mb-3"
                                :hide-details="this.errors.name ? false : true"
                                :error-messages="this.errors.name"
                                :error-count="this.errors.name ? this.errors.name.length : 0"
                            ></v-text-field>
                        </div>
                        <div class="pa-6">
                            <v-text-field
                                v-model="email"
                                outlined
                                label="メールアドレス"
                                class="mb-3"
                                :hide-details="this.errors.email ? false : true"
                                :error-messages="this.errors.email"
                                :error-count="this.errors.email ? this.errors.email.length : 0"
                            ></v-text-field>
                        </div>
                        <div class="text-center py-6">
                            <v-btn
                                @click="registDialog = false"
                                class="mr-3"
                                :disabled="buttonStatus"
                            >
                                キャンセル
                            </v-btn>
                            <v-btn
                                color="deep-orange lighten-1"
                                dark
                                large
                                class="font-weight-bold"
                                :disabled="buttonStatus"
                                @click="addUser()"
                            >
                                登録
                            </v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
        <SearchForm
            @userSearch="userSearch"
            :searchForm="searchForm"
        />
        <v-row 
        justify="center"
        align="center"
        >
            <v-col lg="10">
                <v-card>
                    <UserTable
                        @changePage="changePage"
                        @getUsers="getUsers"
                        :items="items"
                        :page="page"
                        :pageCount="pageCount"
                        :itemsPerPage="itemsPerPage"
                    />
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import SearchForm from "../SearchForm";
    import UserTable from "./UserTable";
    import Search from '../../../mixins/Search.js';
    export default {
        components: { UserTable, SearchForm },
        mixins: [Search],
        data() {
            return {
                name: null,
                email: null,
                registDialog: false,
                buttonStatus: false,
                dialog: false,
                page: 1,
                pageCount: 0,
                itemsPerPage: 0,
                searchForm: {
                    name: '',
                    email: '',
                    label: '氏名',
                },
                searchURL:'/api/admin/users',
                searchScheme: ['name','email'],
                errors: [],
            }
        },
        methods: {
            //ユーザー一覧の取得
            getUsers(scrollFlg) {
                //以下はawaitが終わるまで実行されない
                    let query = this.$route.query;
                    query.scrollFlg = scrollFlg;
                    this.search(query);
            },
            //ユーザーの追加
            addUser() {
                this.buttonStatus = true;
                let post = {
                    name: this.name, 
                    email: this.email, 
                };
                //エラーが存在しいない場合、登録処理を実行
                this.$axios.post("/api/admin/addUser", post)
                .then(response => {
                    if(response.data.result == true){
                        this.$store.commit('setMessege', {
                            text : 'ユーザーを追加しました。',
                            color : 'success'
                        });
                        this.registDialog = false;
                        this.buttonStatus = false;
                        this.getUsers(true);
                    }
                })
                .catch(error => {
                    let errors = error.response.data.errors;
                    this.errors = errors;
                    this.buttonStatus = false;
                });
            },
            //検索ボタンがクリックされた時
            userSearch(value) {
                this.searchForm = value;
                let query = this.getSearchBase();
                this.search(query);
            },
        },
        mounted() {
            this.getUsers();
        },
        watch: {
            registDialog() {
                this.name = null;
                this.email = null;
                this.errors = [];
            },
        },
    }
</script>