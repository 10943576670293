<template>
    <v-container grid-list-md>
        <h2 class="pa-2">ビルダー管理</h2>
        <v-divider></v-divider>
        <div class="text-center my-9">
            <v-dialog
            v-model="registDialog"
            max-width="600px"
            >
                <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        class="font-weight-bold"
                        x-large
                        color="deep-orange lighten-1"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        @click="registDialog = false"
                        >
                        ビルダーを追加
                        </v-btn>
                </template>
                <v-card>
                    <v-card-title class="text-center pa-6">
                        <h4 class="login_title">ビルダーを新規登録</h4>
                    </v-card-title>
                    <v-divider> </v-divider>
                    <v-card-text>
                        <div class="pa-6 pb-0">
                            <v-text-field
                                v-model="builder_name"
                                outlined
                                label="ビルダー名"
                                class="mb-3"
                                :hide-details="this.errors.builder_name ? false : true"
                                :error-messages="this.errors.builder_name"
                                :error-count="this.errors.builder_name ? this.errors.builder_name.length : 0"
                            ></v-text-field>
                        </div>
                        <div class="pa-6">
                            <v-text-field
                                v-model="email"
                                outlined
                                label="メールアドレス"
                                class="mb-3"
                                :hide-details="this.errors.email ? false : true"
                                :error-messages="this.errors.email"
                                :error-count="this.errors.email ? this.errors.email.length : 0"
                            ></v-text-field>
                        </div>
                        <div class="text-center py-6">
                            <v-btn
                                @click="registDialog = false"
                                class="mr-3"
                                :disabled="buttonStatus"
                            >
                                キャンセル
                            </v-btn>
                            <v-btn
                                color="deep-orange lighten-1"
                                dark
                                large
                                class="font-weight-bold"
                                :disabled="buttonStatus"
                                @click="addBuilder()"
                            >
                                登録
                            </v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="registBuilderDialog"
                persistent
                max-width="600px"
            >
                <v-card>
                    <v-card-title class="headline">
                        ビルダーの追加が完了しました。<br>以下の情報を担当者へ伝えてください。
                    </v-card-title>
                    <v-divider> </v-divider>
                    <v-card-text>
                        <div class="pa-6 pb-0">
                            <v-card-text>
                                URL：{{ registBuilderURL }}<br>
                                ID：{{ registBuilderID }}<br>
                                パスワード：{{ registBuilderPassword }}<br><br>
                                <b style="color:red;">※パスワードは再表示できませんのでご注意ください</b>
                            </v-card-text>
                        </div>
                        <div class="text-center py-6">
                            <v-btn
                                color="green darken-1"
                                text
                                @click="registBuilderDialog = false"
                            >
                                閉じる
                            </v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
        <SearchForm
            @userSearch="userSearch"
            :searchForm="searchForm"
        />
        <v-row 
        justify="center"
        align="center"
        >
            <v-col lg="10">
                <v-card>
                    <BuilderTable
                        @changePage="changePage"
                        @getBuilders="getBuilders"
                        :items="items"
                        :page="page"
                        :pageCount="pageCount"
                        :itemsPerPage="itemsPerPage"
                    />
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import SearchForm from "../SearchForm";
    import BuilderTable from "./BuilderTable";
    import Search from '../../../mixins/Search.js';
    export default {
        components: { BuilderTable, SearchForm },
        mixins: [Search],
        data() {
            return {
                builder_name: null,
                email: null,
                registDialog: false,
                registBuilderDialog: false,
                buttonStatus: false,
                page: 1,
                pageCount: 0,
                itemsPerPage: 0,
                registBuilderID: null,
                registBuilderPassword: null,
                registBuilderURL: null,
                searchForm: {
                    name: '',
                    email: '',
                    label: 'ビルダー名',
                },
                searchURL:'/api/admin/builders',
                searchScheme: ['name','email'],
                errors: [],
            }
        },
        methods: {
            //ユーザー一覧の取得
            getBuilders(scrollFlg) {
                //以下はawaitが終わるまで実行されない
                let query = this.$route.query;
                query.scrollFlg = scrollFlg;
                this.search(query);
            },
            //ユーザーの追加
            addBuilder() {
                this.buttonStatus = true;
                let post = {
                    builder_name: this.builder_name, 
                    email: this.email, 
                };
                //エラーが存在しいない場合、登録処理を実行
                this.$axios.post("/api/admin/addBuilder", post)
                .then(response => {
                    if(response.data.result == true){
                        this.registBuilderID = response.data.builderData.id,
                        this.registBuilderPassword = response.data.builderData.password,
                        this.registBuilderURL = response.data.builderData.url,
                        this.registBuilderDialog = true;
                        this.registDialog = false;
                        this.buttonStatus = false;
                        this.getBuilders(true);
                    }
                })
                .catch(error => {
                    let errors = error.response.data.errors;
                    this.errors = errors;
                    this.buttonStatus = false;
                });
            },
            //検索ボタンがクリックされた時
            userSearch(value) {
                this.searchForm = value;
                let query = this.getSearchBase();
                this.search(query);
            },
        },
        mounted() {
            this.getBuilders();
        },
        watch: {
            registDialog() {
                this.builder_name = null;
                this.email = null;
                this.errors = [];
            },
        },
    }
</script>