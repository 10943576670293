<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer
            no-data-text="データがありません"
        >
            <template v-slot:item="{ item }">
                <!-- PC -->
                <tr>
                    <td>
                        {{ item.created_at | moment(dateFormat)}}
                    </td>
                    <td>
                        {{ builders[item.builder_id] }}
                    </td>
                    <td>
                        {{ item.name }}
                    </td>
                    <td>
                        <div class="pa-4">
                            <v-select
                                :error-messages="quoteStatusErrors[item.id]"
                                v-model="item.status"
                                :items="statuses"
                                item-text="label"
                                item-value="value"
                                color="cyan darken-3"
                                dense
                                outlined
                                label="ステータス未選択"
                                solo
                                flat
                                @change="changedStatus(item.id, item.status)"
                            ></v-select>
                        </div>
                    </td>
                    <td>
                        <v-btn
                            class="mx-2"
                            fab
                            small
                            dark
                            color="red darken-1"
                            depressed
                            @click="deleteConfirm(item.id, item.name)"
                        >
                            <v-icon dark>
                                mdi-trash-can
                            </v-icon>
                        </v-btn>
                    </td>
                </tr>
            </template>
        </v-data-table>

        <div class="text-center py-5">
            <v-pagination
                v-model="dataPage"
                :length="pageCount"
                @input="changePage()"
                color="indigo"
            ></v-pagination>
        </div>
        <v-dialog v-model="deleteDialog" persistent max-width="400">
            <v-card>
                <v-card-title class="subtitle-1 font-weight-bold">
                    {{ deleteName }} を削除しますか？
                </v-card-title>
                <v-card-actions class="pa-4">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="gray darken-1"
                        depressed
                        @click="deleteDialog = false"
                    >
                        キャンセル
                    </v-btn>
                    <v-btn
                        color="red darken-1"
                        dark
                        depressed
                        @click="deleteQuote()"
                    >
                        削除
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import moment from "moment";
export default {
    props: {
        statuses: Array,
        items: Array,
        page: Number,
        pageCount: Number,
        itemsPerPage: Number,
        builders: {},
    },
    data() {
        return {
            dateFormat: "YYYY-MM-DD",
            dataPage: this.page,
            quoteStatusErrors: {},
            headers: [
                {
                    text: "追加日",
                    sortable: false,
                    value: "date",
                    width: "12%",
                },
                {
                    text: "ビルダー名",
                    sortable: false,
                    value: "builders",
                    width: "30%",
                },
                {
                    text: "氏名",
                    sortable: false,
                    value: "name",
                    width: "18%",
                },
                {
                    text: "ステータス",
                    sortable: false,
                    value: "status",
                    width: "30%",
                },
                {
                    text: "",
                    sortable: false,
                    value: "delete",
                    width: "10%",
                },
            ],
            //削除関係
            deleteDialog: false,
            deleteID: null,
            deleteName: null,
        };
    },
    methods: {
        //ページングがクリックされた時
        changePage() {
            let value = {
                page: this.dataPage,
                pageCount: this.pageCount,
                itemsPerPage: this.itemsPerPage,
            };
            this.$emit("changePage", value);
        },
        changedStatus(id, status) {
            let post = {
                id: id,
                status: status,
            };
            post = {
                id: id,
                status: status,
            };
            this.$store.commit("resetMessage");
            this.$axios
                .post("/api/admin/editStatus", post)
                .then((response) => {
                    if (response.data.result == true) {
                        this.$store.commit("setMessege", {
                            text: "スターテスを変更しました。",
                            color: "success",
                        });
                    }
                    this.$emit("getQuote", false);
                })
                .catch((error) => {
                    let errors = error.response.data.errors.status;
                    this.$set(this.quoteStatusErrors, id, errors);
                });
        },
        deleteConfirm(id, name) {
            this.deleteDialog = true;
            this.deleteID = id;
            this.deleteName = name;
        },
        deleteQuote() {
            this.$axios
                .post("/api/admin/deleteQuote/" + this.deleteID)
                .then((response) => {
                    if (response.data.result == true) {
                        this.$store.commit("setMessege", {
                            text: "見積依頼を削除しました。",
                            color: "success",
                        });
                    }
                    this.deleteDialog = false;
                    this.$emit("getQuote", false);
                })
                .catch((error) => {
                    error;
                    this.$store.commit("setMessege", {
                        text:
                            "削除に失敗しました。もう一度やり直してください。",
                        color: "error",
                    });
                });
        },
    },
    filters: {
        /**
         * @param {Date} value    - Date オブジェクト
         * @param {string} format - 変換したいフォーマット
         */
        moment(value, format) {
            return moment(value).format(format);
        },
    },
};
</script>

<style type="scss">
@media screen and (min-width: 769px) {
    .layout {
        display: block;
        margin: 0 !important;
    }
}
@media screen and (max-width: 768px) {
    .v-select.v-input--dense .v-select__selection--comma {
        margin: 5px 4px 3px 0;
        font-size: 12px;
    }
    .v-select__slot label {
        font-size: 12px;
    }
}
.flex-content {
    padding: 15px 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.flex-item {
    padding: 5px;
    width: 50%;
    height: auto !important;
    font-weight: bold;
}
</style>
