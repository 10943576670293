<template>
    <v-container grid-list-md>
        <h2 class="pa-2">見積もり依頼一覧</h2>
        <v-divider></v-divider>
        <div class="text-center my-9">
            <v-dialog v-model="registDialog" max-width="600px">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        class="font-weight-bold"
                        x-large
                        color="amber lighten-1"
                        v-bind="attrs"
                        v-on="on"
                    >
                        依頼を追加
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title class="text-center pa-6">
                        <h4 class="login_title">見積依頼を登録</h4>
                    </v-card-title>
                    <v-divider> </v-divider>
                    <v-card-text>
                        <div class="pa-6">
                            <v-text-field
                                outlined
                                label="氏名"
                                v-model="name"
                                :hide-details="errors.name ? false : true"
                                :error-messages="errors.name"
                                :error-count="errors.name ? errors.name.length : 0"
                            ></v-text-field>
                        </div>
                        <div class="text-center py-6">
                            <v-btn @click="registDialog = false" class="mr-3">
                                キャンセル
                            </v-btn>
                            <v-btn
                                large
                                :disabled="buttonStatus"
                                color="amber lighten-1"
                                class="font-weight-bold"
                                @click="addQuote()"
                            >
                                登録
                            </v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>

        <v-row justify="center" align="center">
            <v-col lg="10">
                <v-card flat>
                    <v-card-title class="px-4 py-2 teal lighten-1">
                        <h3 class="subtitle-1 font-weight-bold white--text">
                            検索
                        </h3>
                    </v-card-title>
                    <v-divider> </v-divider>
                    <v-row class="pa-6 pb-2">
                        <v-col cols="12" md="4">
                            <v-text-field
                                label="名前"
                                v-model="searchForm.name"
                                outlined
                                hide-details
                                color="cyan darken-3"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                            <v-select
                                label="ステータス"
                                v-model="searchForm.status"
                                :items="[{label:'全て' , value:''} , ...statuses]"
                                item-text="label"
                                item-value="value"
                                outlined
                                hide-details
                                flat
                                color="cyan darken-3"
                            ></v-select>
                        </v-col>

                        <v-col cols="12" md="4">
                            <v-select
                                v-model="searchForm.situation"
                                :items="situation"
                                item-text="label"
                                item-value="value"
                                outlined
                                hide-details
                                label="状況"
                                flat
                                color="cyan darken-3"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <div class="text-center pb-4">
                        <v-btn class="ma-2" outlined color="cyan darken-3" @click="quoteSearch()">
                            <v-icon>mdi-magnify</v-icon>
                            検索
                        </v-btn>
                    </div>
                </v-card>
            </v-col>
        </v-row>

        <v-row justify="center" align="center">
            <v-col lg="10">
                <v-card>
                    <QuoteTable
                        @changePage="changePage"
                        @getQuote="getQuote"
                        :items="items"
                        :page="page"
                        :pageCount="pageCount"
                        :itemsPerPage="itemsPerPage"
                        :statuses="statuses"
                    />
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import QuoteTable from "./QuoteTable";
import Search from '../../../mixins/Search.js';
export default {
    components: { QuoteTable },
    mixins: [Search],
    data() {
        return {
            name: null,
            buttonStatus: false,
            registDialog: false,
            errors: [],
            //検索条件
            searchForm:{
                //検索のデフォルト値
                name:'',
                status:'',
                situation:'',
            },
            //search用
            searchURL:'/api/builder/quotes',
            searchScheme: ['name','status' , 'situation'],
            //マスターデータ
            statuses: [],
            situation: [
                {
                    label: "受注・失注を除く",
                    value: "",
                },
                {
                    label: "全て",
                    value: "all",
                },
            ],
        };
    },
    methods: {
            addQuote() {
                this.buttonStatus = true;
                let post = {
                    name: this.name, 
                };
                //エラーが存在しいない場合、登録処理を実行
                this.$axios.post("/api/builder/addQuote", post)
                .then(response => {
                    if(response.data.result == true){
                        this.$store.commit('setMessege', {
                            text : '見積依頼を追加しました。',
                            color : 'success'
                        });
                        this.registDialog = false;
                        this.buttonStatus = false;
                        this.getQuote(true);
                    }
                })
                .catch(error => {
                    let errors = error.response.data.errors;
                    this.errors = errors;
                    this.buttonStatus = false;
                });
            },
            
            getQuote(scrollFlg) {
                let query = this.$route.query;
                query.scrollFlg = scrollFlg;
                this.search(query);
            },
            //検索ボタンがクリックされた時
            quoteSearch() {
                let value = {};
                for (const key of this.searchScheme) {
                    value[key] = this.searchForm[key];
                }
                this.searchForm = value;
                let query = this.getSearchBase();
                this.search(query);
            },
        },
        watch: {
            registDialog() {
                this.name = null;
                this.errors = [];
            },
        },
        mounted() {
            (async() => {
                //先にマスターデータを取得しておく。
                await this.$axios.get("/api/masters/statuses")
                    .then(response => {
                        this.statuses = response.data;
                })
                this.getQuote();
            })();
            
        },
};
</script>

<style type="scss">
.theme--light.v-sheet--outlined {
    border: none !important;
}
</style>
