<template>
    <v-navigation-drawer
    app
    clipped
    mobile-breakpoint="960"
    
    color="blue lighten-5"
    >
        <v-container>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title
                        class="title indigo--text text--darken-4"
                    >
                        管理者 MENU
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list nav dense>
                <template v-for="nav_list in nav_lists">
                    <v-list-item
                        v-if="!nav_list.lists"
                        :to="nav_list.link"
                        :href="nav_list.href"
                        :target="nav_list.target"
                        :key="nav_list.name"
                        :exact="nav_list.exact"
                        @click="menu_close"
                    >
                        <v-list-item-icon>
                            <v-icon>{{ nav_list.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ nav_list.name }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </v-container>
    </v-navigation-drawer>
</template>

<script>
export default {
    methods: {
        menu_close() {
            this.nav_lists.forEach((nav_list) => (nav_list.active = false));
        },
    },
    props: {
        drawer: {
            type: Boolean,
            default: null
        }
    },
    computed: {
        setDrawer: {
            get () { return this.drawer },
            set (val) { return this.$emit('update:drawer', val) }
        },
        nav_lists () {
            const route = this.$route
            // メニューにqueryが入るとactiveにならない対策
            // exact falseとすることで前方一致となり対応可能だが、/admin の場合に全てパスに反応してactiveになってしまう。
            // 以下で/admin以下にいる時はqueryを含んだ全パスを取得し、それはactiveのベースとなるlinkにバインドする。
            // ただしメニュークリックで検索条件のリセットはできなくなる
            // see also https://qiita.com/HorikawaTokiya/items/73bda3c1ebd0c0bc76ae
            const adminPath = route.path === '/admin' ? route.fullPath : '/admin'

            return [
                {
                    name: "見積依頼一覧",
                    icon: "mdi-view-list-outline",
                    link: adminPath,
                    exact:true,
                },
                {
                    name: "ビルダー管理",
                    icon: "mdi-account-supervisor",
                    link: "/admin/list_builder",
                    exact:false,
                },
                {
                    name: "ユーザー管理",
                    icon: "mdi-account-settings",
                    link: "/admin/list_user",
                    exact:false,
                },
            ];
    }
    },
    data() {
        return {
          
        }
    },
};
</script>
