<template>
    <div>
        <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="itemsPerPage"
        hide-default-footer
        no-data-text = 'データがありません'
        >
            <template v-slot:item.edit="{ item }">
                <div class="pa-2">
                    <v-btn
                    class="mx-2"
                    fab
                    small
                    dark
                    color="blue-grey darken-3"
                    depressed
                    @click="editConfirm(item.id, item.builder_name, item.email)"
                    >
                        <v-icon dark>
                            mdi-pencil
                        </v-icon>
                    </v-btn>
                    <v-btn
                    class="mx-2"
                    fab
                    small
                    dark
                    color="red darken-1"
                    depressed
                    @click="deleteConfirm(item.id, item.builder_name)"
                    >
                        <v-icon dark>
                            mdi-trash-can
                        </v-icon>
                    </v-btn>
                    <v-btn
                    class="mx-2 px-2 py-5"
                    small
                    color="blue lighten-4"
                    depressed
                    @click="resetConfirm(item.id, item.builder_name, item.email)"
                    >
                        <v-icon
                        small
                        class="mr-1"
                        >
                            mdi-key-variant
                        </v-icon>
                        パスワードリセット
                    </v-btn>
                </div>
            </template>
        </v-data-table>
        <div class="text-center py-5">
            <v-pagination
                v-model="dataPage"
                :length="pageCount"
                @input="changePage()"
                color="indigo"
            ></v-pagination>
        </div>

        <v-dialog
        v-model="dialog"
        max-width="500"
        >
        <v-card>

            <v-card-title>
                <span v-show="isDetail === 'edit'" class="heading-5 font-weight-bold">ユーザーを編集</span>
                <span v-show="isDetail === 'delete'" class="subtitle-1 font-weight-bold">{{ deleteName }} を削除しますか？<br>紐付いている見積依頼も削除されます。</span>
                <span v-show="isDetail === 'reset_pass'" class="subtitle-1 font-weight-bold">{{ editName }} のパスワードをリセット</span>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text v-show="isDetail === 'edit'">
                <div class="pa-6">
                    <v-text-field
                        v-model="editName"
                        label="ビルダー名"
                        outlined
                        class="mb-4"
                        :hide-details="this.errors.builder_name ? false : true"
                        :error-messages="this.errors.builder_name"
                        :error-count="this.errors.builder_name ? this.errors.builder_name.length : 0"
                    ></v-text-field>
                    <v-text-field
                        v-model="editEmail"
                        label="メールアドレス"
                        outlined
                        :hide-details="this.errors.email ? false : true"
                        :error-messages="this.errors.email"
                        :error-count="this.errors.email ? this.errors.email.length : 0"
                    ></v-text-field>
                </div>
            </v-card-text>

            <v-card-text v-show="isDetail === 'reset_pass'">
                <div class="pa-6 pb-0">
                    {{ editEmail }} に新しいパスワードを送信します。
                </div>
            </v-card-text>

            <v-card-actions class="pa-4">
                <v-spacer></v-spacer>
                <v-btn
                color="gray darken-1"
                :disabled="buttonStatus"
                @click="dialog = false"
                >
                    キャンセル
                </v-btn>
                <v-btn
                v-show="isDetail === 'edit'"
                class="deep-orange lighten-1"
                large
                dark
                :disabled="buttonStatus"
                @click="editBuilder()"
                >
                    更新する
                </v-btn>
                <v-btn
                v-show="isDetail === 'delete'"
                class="red darken-2"
                large
                dark
                :disabled="buttonStatus"
                @click="deleteBuilder()"
                >
                    削除
                </v-btn>
                <v-btn
                v-show="isDetail === 'reset_pass'"
                class="deep-orange lighten-1"
                large
                dark
                :disabled="buttonStatus"
                @click="resetPassword()"
                >
                    OK
                </v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        props: {
            items: {
                type: Array,
            },
            page: Number,
            pageCount: Number,
            itemsPerPage: Number,
        },
        data() {
            return {
                id: null,
                builder_name: null,
                email: null,
                deleteID: null,
                deleteName: null,
                editID: null,
                editName: null,
                editEmail: null,
                isDetail: null,
                dialog: false,
                buttonStatus: false,
                dataPage: this.page,
                errors: [],
                headers: [
                    {
                        text: 'ビルダー名',
                        sortable: false,
                        value: 'builder_name',
                        width: '25%'
                    },
                    {
                        text: 'メールアドレス',
                        sortable: false,
                        value: 'email',
                        width: '35%'
                    },
                    {
                        text: '',
                        sortable: false,
                        value: 'edit',
                        width: '40%'
                    }
                ],
            }
        },
        methods: {
            //ビルダーの編集
            editConfirm(id,builder_name,email) {
                this.isDetail = 'edit';
                this.dialog = true;
                this.editID = id;
                this.editName = builder_name;
                this.editEmail = email;
            },
            editBuilder() {
                this.buttonStatus = true;
                let post = {
                    builder_name: this.editName,
                    email: this.editEmail,
                };
                //エラーが存在しいない場合、登録処理を実行
                this.$axios.post("/api/admin/editBuilder/" + this.editID, post)
                .then(response => {
                    if(response.data.result == true){
                        this.$store.commit('setMessege', {
                            text : 'ビルダーを変更しました。',
                            color : 'success'
                        });
                        this.buttonStatus = false;
                        this.dialog = false;
                        this.$emit('getBuilders', false);
                    }
                })
                .catch(error => {
                    let errors = error.response.data.errors;
                    this.errors = errors;
                    this.buttonStatus = false;
                });
            },
            //ビルダーの削除
            deleteConfirm(id,builder_name) {
                this.isDetail = 'delete';
                this.dialog = true;
                this.deleteID = id;
                this.deleteName = builder_name;
            },
            deleteBuilder() {
                this.buttonStatus = true;
                this.$axios.post("/api/admin/deleteBuilder/" + this.deleteID)
                .then(response => {
                    if(response.data.result == true){
                        this.$store.commit('setMessege', {
                            text : 'ビルダーを削除しました。',
                            color : 'success'
                        });
                        this.buttonStatus = false;
                        this.dialog = false;
                        this.$emit('getBuilders', true);
                    }
                })
                .catch(error => {
                    error;
                    this.buttonStatus = false;
                });
            },
            //ビルダーのパスワードリセット
            resetConfirm(id,builder_name,email) {
                this.isDetail = 'reset_pass';
                this.dialog = true;
                this.editID = id;
                this.editName = builder_name;
                this.editEmail = email;
            },
            resetPassword() {
                this.buttonStatus = true;
                this.$axios.post("/api/admin/builderResetPassword/" + this.editID)
                .then(response => {
                    if(response.data.result == true){
                        this.$store.commit('setMessege', {
                            text : 'パスワードをリセットしました。',
                            color : 'success'
                        });
                        this.buttonStatus = false;
                        this.dialog = false;
                        this.$emit('getBuilders', true);
                    }
                })
                .catch(error => {
                    error;
                    this.buttonStatus = false;
                });
            },
            //ページングがクリックされた時
            changePage() {
                let value = {
                    page: this.dataPage,
                    pageCount: this.pageCount,
                    itemsPerPage: this.itemsPerPage,
                };
                this.$emit('changePage', value);
            },
        },
        watch: {
            dialog() {
                this.errors = [];
            },
        },
    }
</script>