<template>
    <v-app>
        <div class="ma-9 pa-9 text-center">
            <span class="pa-2 secondary rounded-circle d-inline-block">
                <v-icon
                large
                color="white"
                >
                mdi-exclamation-thick
                </v-icon>
            </span>
            <div class="text-h1 my-4">404</div>
            <p>Not Found</p>
            <p>お探しのページは見つかりませんでした</p>
        </div>
        <div class="mt-4 text-center">

            <v-btn
            v-if="pagesName === 'NotFoundAdmin'"
            depressed
            dark
            color="indigo"
            to="/admin/"
            >
            管理者TOPへ

            </v-btn>

            <v-btn
            v-else
            depressed
            dark
            color="cyan"
            to="/"
            >
            TOPへ
            </v-btn>

        </div>
    </v-app>
</template>

<script>
export default {
    data() {
        return {
            pagesName: this.$route.name
        }
    }
}
</script>