import Vue from 'vue'
import VueRouter from 'vue-router'
import Builders from '../components/Builders';
import BuildersVideo from '../components/Builders/Video';
import BuildersQuote from '../components/Builders/Quote';
import BuildersLogin from '../components/Builders/Login';
import BuildersPass from '../components/Builders/Password';
import Admin from '../components/Admin';
import AdminLogin from '../components/Admin/Login';
import AdminQuote from '../components/Admin/Quote';
import AdminPass from '../components/Admin/Password';
import BuildersEdit from '../components/Admin/BuildersEdit';
import UserEdit from '../components/Admin/UserEdit';
import NotFound from '../components/NotFound';


// Vue.use(Vuex)
Vue.use(VueRouter);

const routes = [{
        path: '/login',
        component: BuildersLogin,
        props: true,
        meta: { isPublic: true },
    },
    {
        path: '/',
        name: 'builders',
        component: Builders,
        props: true,
        children: [{
                path: '',
                component: BuildersVideo,
            },
            {
                path: '/quote_list',
                component: BuildersQuote,
            },
            {
                path: '/password',
                component: BuildersPass,
            },
        ],
    },


    {
        path: '/admin/login',
        component: AdminLogin,
        props: true,
        meta: { isPublic: true },
    },
    {
        path: '/admin',
        name: 'admin',
        component: Admin,
        props: true,
        children: [{
                path: '',
                component: AdminQuote,
            },
            {
                path: '/admin/list_builder',
                component: BuildersEdit,
            },
            {
                path: '/admin/list_user',
                component: UserEdit,
            },
            {
                path: '/admin/password',
                component: AdminPass,
            },
        ],
    },
    {
        name: 'NotFound',
        path: '*',
        component: NotFound,
    },
    {
        name: 'NotFoundAdmin',
        path: '/admin/*',
        component: NotFound,
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

import { checkAuthAdmin, checkAuthBuilder } from '../mixins/Auth.js';
router.beforeEach((to, from, next) => {

    //isPublicがtrueの場合には必ずアクセス可能
    //各ルートに meta: { isPublic: true } を追加します。
    if (to.matched.some(page => page.meta.isPublic)) {
        next();
    } else {
        //async/awaitでそれぞれのログイン状況を取得する
        (async() => {
            let adminLogin = await checkAuthAdmin();
            let builderLogin = await checkAuthBuilder();

            //ログイン済(admin)
            if (to.path.indexOf('/admin') === 0 && adminLogin) {
                next();
            }
            //ログイン済(builder)
            else if (to.path.indexOf('/admin') !== 0 && builderLogin) {
                next();
            }
            //ログインしていない
            else {
                if (to.path.indexOf('/admin') === 0) {
                    next({ path: '/admin/login' });
                } else {
                    next({ path: '/login' });
                }
            }
        })();
    }
});

// router.beforeEach((to, from, next) => {
//     if (to.matched.some(page => page.meta.isPublic)) {
//         next()
//     } else {
//         next('/spalogin')
//     }
// })

export default router